<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Contas Fixas</h2>
              <p>Essas são as suas despesas e receitas fixas, como conta de água e luz.</p>
            </div>
          </div>
          <div class="slide-tab-header">
            <slide-tab tabPositionMax="50" firstTabColor="last-tab-color" lastTabColor="first-tab-color" @changeTabCallBack="changeTab"/>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-sm-12 hidden-lg hidden-md ">
        <button class="btn btn-success pull-right ml-5 action-income w49_5-m mBottom-10-m" type="button" @click="createTransaction('create-transaction-modal', 'income', false)"><span><i class="mdi mdi-plus-circle-outline"></i></span> Lançar receitas </button>
        <button class="btn btn-danger pull-right ml-5 action-income w49_5-m m-left-none-m mBottom-10-m" type="button" @click="createTransaction('create-transaction-modal', 'expense', false)"><span><i class="mdi mdi-minus-circle-outline"></i></span> Lançar despesa </button>
      </div>
      <div class="col-sm-9 p-right-none" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Filtro</label>
          </div>
        </div>
        <div v-for="(filter, n) in filters" class="form-row" :key="n">
          <div class="form-group col-lg-3 col-md-3 col-sm-3">
            <select-input
              v-model="filters[n].field"
              :reduce="(field) => field.value"
              :options="fields"
              :clearable="true"
            />
          </div>
          <div class="form-group col-lg-8 col-md-8 col-sm-8 ">
            <select-input
              v-if="filters[n].field == 'all'"
              multiple
              :reduce="(field) => field.value"
              label="label"
              v-model="filters[n].values"
              :options="filterAll"
              :clearable="true"
              @input="filterRequest"
            />
            <select-input
              v-else-if="filters[n].field == 'card_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="cards"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'account_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="wallets"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'category'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="categories"
              :clearable="true"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="Selecione um campo"
              disabled
            />
          </div>
          <div class="form-group col-lg-1 hide">
            <button
              @click="removeFilter(n)"
              class="btn btn-danger btn-sm"
              style="height: 40px;"
            >
              <i class="fas fa-trash color-white"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-row hidden-xs">
          <div class="col-lg-12 col-md-12 col-sm-12 text-right" style="padding-right: 0px;">
            <label>&nbsp;</label>
          </div>
        </div>
        <div class="input-group mb-3" style="margin-top:5px;">
          <date-range-picker
            class="form-control mr-sm-2"
            value
            :options="optionsDate"
            @change="changeDate"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-left hide">
        <button @click="addFilter" type="button" class="btn btn-primary">
          <i class="fas fa-plus"></i> Adicionar Filtro
        </button>
      </div>
      <div class="col-sm-12 hide">
        <div class="relative">
          <div class="include-card-expense">
            <input type="checkbox" id="for_is_card" v-model="has_card" @change="request(tabIncome)">
            <label style="margin-top: 10px;" for="for_is_card" class="to-left">Incluir despesas de cartão</label>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-right">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="transaction-amount">
          <!-- item -->
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i
                    class="mdi mdi-checkbox-blank-circle text-primary"
                  ></i>
                </div>
                <div class="transaction-info">
                  <strong>{{report.report.transactions.paid_value | currency}}</strong>
                  <span>Pagas</span>
                </div>
              </div>
              <!-- item -->
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i
                    class="mdi mdi-checkbox-blank-circle text-danger"
                  ></i>
                </div>
                <div class="transaction-info">
                  <strong>{{report.report.transactions.due_value | currency}}</strong>
                  <span>Em atraso</span>
                </div>
              </div>
              <!-- item -->
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i class="mdi mdi-checkbox-blank-circle text-info"></i>
                </div>
                <div class="transaction-info">
                  <strong>{{report.report.transactions.open_value | currency}}</strong>
                  <span>Em aberto</span>
                </div>
              </div>
            </div>

            <div class="transaction-visual">
              <v-chart class="chart" :option="option" />
            </div>
            <div style="clear:both"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 hidden-sm">
        <div class="card">
          <div class="card-header">
            <h4>Status</h4>
          </div>
          <div class="card-body" style="padding-top: 5px;">
            <div class="transaction-progress">
              <div class="item mt-5">
                <strong class="pull-right ng-binding">{{report.report.transactions.paid_number}}</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-primary"></i> Pagas</p>
                <div class="progress progress-bar-success-alt">
                  <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" :style="`width:${report.report.transactions.paidPercentage}%`">
                  </div>
                </div>
              </div>
              <div class="item mt-5">
                <strong class="pull-right ng-binding">{{report.report.transactions.due_number}}</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-info"></i> Em atraso</p>
                <div class="progress progress-bar-danger-alt">
                  <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" :style="`width:${report.report.transactions.duePercentage}%`">
                  </div>
                </div>
              </div>
              <div class="item mt-5">
                <strong class="pull-right ng-binding">{{report.report.transactions.open_number}}</strong>
                <p class="text-muted"> <i class="mdi mdi-checkbox-blank-circle-outline text-info"></i> Em aberto</p>
                <div class="progress progress-bar-primary-alt">
                  <div class="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100" :style="`width:${report.report.transactions.openPercentage}%`">
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="card">
          <div class="card-header">
            <h4>Total</h4>
          </div>
          <div class="card-body" style="padding-top: 0;">
            <section class="text-center">
              <h1 class="reports-expenses text-left font-w-600 ng-binding" style="margin-top: 0px;">{{report.total_value | currency }}</h1>
              <h4 class="text-left block " v-if="tabIncome == 'expense'">Contas a pagar <strong>{{report.report.transactions.open_number}}</strong></h4>
              <h4 class="text-left block " v-if="tabIncome == 'income'">Contas a receber <strong>{{report.report.transactions.open_number}}</strong></h4>
              <hr>
              <div class="chart-insights  text-left">
                <p v-if="tabIncome == 'expense'">Você pagou</p>
                <p v-if="tabIncome == 'income'">Você recebeu</p>
                <h4 style="margin: 5px;"><strong class="ng-binding">{{report.report.transactions.paid_value | currency }}</strong> de
                <strong class="ng-binding">{{report.total_value | currency }}</strong></h4>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="cashflow-print" @click="print"><i class="fa fa-print hide"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="row hide">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Valor</label>
                        <input class="form-control currency ng-pristine ng-untouched ng-valid ng-empty" data-thousands="." data-decimal="," placeholder="0,00" name="value" ng-model="CashFlow.event.value">
                      </div>
                    
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Tipo</label>
                        <select class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="type" ng-model="CashFlow.event.type_event"><option value="? undefined:undefined ?" selected="selected"></option>
                          <option value="income">Entrada</option>
                          <option value="expense">Saída</option>
                        </select>
                      </div>
                      <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4">
                        <label>Categoria</label>
                        <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="description" placeholder="Categoria" ng-model="CashFlow.event.description">
                      </div>
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                        <label>Data</label>
                        <input type="text" class="form-control datepicker-current ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" required="" past="true" placeholder="Data" name="due_date" ng-model="CashFlow.event.date">
                      </div>
                      <div class="col-sm-2 col-sm-4 col-lg-2 col-xs-2 text-right">
                        <br>
                        <button class="btn btn-primary btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.renderVar('')"><i class="fa fa-check"></i></button>
                        <button class="btn btn-danger btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.hideAdd()"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="display-4">Transações</h3>
              <div class="row hide">
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Status
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Conta/Cartão
                </div>
                <div class="col-sm-1 col-sm-1 col-lg-1 col-xs-1 col-xs-1 text-right">
                  Data
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="report.transactions.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="report.transactions">
                <li class="list-group-item ng-scope" v-for="(item, i) in report.transactions" :key="i">
                  <div class="row">
                    <div class="col-sm-2 col-lg-2 col-xs-4">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-3 col-lg-3 col-xs-8 text-right">
                      <strong v-if="item.status == 2 && isOpen(item.transaction_date)"><i class="mdi mdi-checkbox-blank-circle text-info"></i> Aberto</strong>  
                      <strong v-if="item.status == 2 && isDue(item.transaction_date)"><i class="mdi mdi-checkbox-blank-circle text-danger"></i> Em atraso</strong>  
                      <strong v-if="item.status == 2 && isToday(item.transaction_date)"><i class="mdi mdi-checkbox-blank-circle text-warning"></i> Vence hoje</strong>
                      <strong v-if="item.status == 1"><i class="mdi mdi-checkbox-blank-circle text-success"></i> Pago</strong>

                      <span class="label label-primary" v-if="item.type == 'P'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Parcelado: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'M'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Mensal: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'B'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Bimestral: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'T'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Trimestral: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'S'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Semestral: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'A'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Anual: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'Q'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Quinzenal: {{item.installment}}/{{item.installments}}</span>
                      <span class="label label-primary" v-if="item.type == 'E'" style="font-weight: 100; background-color: #c1c1c1; margin-left: 5px;">Semanal: {{item.installment}}/{{item.installments}}</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-12">
                      <strong class="ng-binding" v-if="item.account">{{item.account.name }}</strong>
                      <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                      <i class="fa fa-credit-card" v-if="item.card" style="margin-left:5px;"></i>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-6">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}`">
                          <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                        </div>
                        &nbsp;<span>{{item.category.name}}</span>
                      </span>
                    </div>
                    <div class="col-sm-3 col-lg-3 col-xs-6 text-right">
                      <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                    </div>
                    <div class="col-sm-12 col-xs-12 text-right">
                      <button type="button" v-tooltip.top="'Editar'" class="btn-edit-category" @click="editTransaction('edit-transaction-modal', item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" v-tooltip.top="'Paga'" class="btn-pay-transaction" v-if="item.status != 1" @click="payTransaction(item)">
                        <span>
                          <i class="fa fa-check"></i>
                        </span>
                      </button>
                      <button type="button" v-tooltip.top="'Remover'" class="btn-remove-category" @click="removeTransaction(item)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col-sm-12 col-xs-12" v-if="item.description">
                      <hr class="hr-transactions">
                      <span class="ng-binding transaction-note">{{item.description }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import SelectInput from '@/components/SelectInput.vue';
import Swal from 'sweetalert2';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import VChart, { THEME_KEY } from "vue-echarts";
import SlideTab from '@/components/SlideTab'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    SelectInput,
    VChart,
    SlideTab,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      has_card: false,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      cards: [],
      categories: [],
      wallets: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {
        report: {
          transactions: {},
        },
        transactions: {},
      },
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
      isLoadingWallet: true,
      isLoadingCards: true,
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      optionCat: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              '</h3> <span class="text-left" style="display:block; color: #000 !important;">' +
              params.percent +
              "%</span></span>";

            return template;
          },
        },
        toolbox: {
          show: false,
        },
        color: ["#f62d51", "#dddddd", "#ffbc34"],
        calculable: true,
        series: [
          {
            name: "Top",
            type: "pie",
            roseType: "radius",
            radius: [30, 70],
            center: ["50%", "50%"],
            data: [],
          },
        ],
      },
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    console.log('Mês', this.monthStart);
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');
    this.request();

    AppService.getCards({
      from: this.from,
      to: this.to,
    }).then(
      (response) => {
        console.log('Cards');
        console.log(response);
        this.cards = response.cards;
        this.isLoadingCards = false;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getWallets({}).then(
      (response) => {
        this.isLoadingWallet = false;
        this.wallets = response.wallets;
        console.log(response);
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getCategories({}).then(
      (response) => {
        this.categories = response.categories;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    this.$root.$on('register.transaction', this.request);
  },
  methods: {
    changeTab(tab) {
      this.miniTab.tab = tab;
      switch (tab) {
        case 0:
          this.tabIncome = 'expense';
          break;
        case 1:
          this.tabIncome = 'income';
          break;
        default:
          break;
      }
      this.request();
    },
    createTransaction(id) {
      const data = {
        isCard: false,
        type: 'income',
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: true,
        invoice_pay: false,
        invoice: null,
        account: null,
        date: moment(new Date()).format('DD/MM/YYYY'),
        check_sub: true,
      };
      this.$root.$emit('show.modal', id, data);
    },
    localStamp() {
      return new Date().toLocaleDateString('pt-BR', {timeZone: 'America/Sao_Paulo'})
    },
    isOpen(date) {
      let dateMoviment = moment(date, 'YYYY-MM-DD').format('x');
      let to = moment(this.localStamp(), 'DD/MM/YYYY').format('x');

      if(dateMoviment > to) {
        return true;
      }
      return false;
    },
    isDue(date) {
      let dateMoviment = moment(date, 'YYYY-MM-DD').format('x');
      let to = moment(this.localStamp(), 'DD/MM/YYYY').format('x');
      if(dateMoviment < to) {
        return true;
      }
      return false;
    },
    isToday(date) {
      let dateMoviment = moment(date, 'YYYY-MM-DD').format('x');
      let to = moment(this.localStamp(), 'DD/MM/YYYY').format('x');
      if(dateMoviment == to) {
          return true;
      }
      return false;
    },
    checkDue(transaction_date) {
      if(moment() < moment(transaction_date)) {
        return true;
      }
      return false;
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request(requestType = null) {
      console.log(requestType);
      if (requestType) {
        this.tabIncome = requestType;
        if (requestType == 'income') {
          this.changeTab(1, 160);
        }
      }

      AppService.getBills({
        from: this.from,
        to: this.to,
        type: this.tabIncome,
        filter: this.filters,
        check_sub: true,
        has_card: this.has_card,
      }).then(
        (response) => {
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
          this.option.series[0].data = [];
          this.isLoading = false;
          this.report = response;
          if (this.report.report && this.report.report.transactions.paidPercentage > 0 || this.report.report.transactions.duePercentage || this.report.report.transactions.openPercentage) {
            this.option.series[0].data.push({
              value: this.report.report.transactions.paidPercentage, name: "Pagas",
            });
            this.option.series[0].data.push({
              value: this.report.report.transactions.duePercentage, name: "Em atraso",
            });
            this.option.series[0].data.push({
              value: this.report.report.transactions.openPercentage, name: "Em aberto"
            });
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
    editTransaction(id, item) {
      let data       = item;
      data.date      = moment(data.transaction_date, "YYYY-MM-DD").format('DD/MM/YYYY');
      data.value     = item.amount;
      data.note      = item.description;
      data.parent   = item.category.parent;
      data.bill_type = {
        type: item.type,
      };
      this.$root.$emit('show.modal', id, data);
    },
    deleteTransaction(id) {
      AppService.deleteTransaction(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Transação removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    payConfirm(transaction) {
      AppService.payTransaction({
        id: transaction.id,
      }).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Baixa efetuada com sucesso!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeTransaction(item) {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover essa transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteTransaction(item.id);
        }
      });
    },
    payTransaction(item) {
      Swal.fire({
        title: `Dar baixa na transação?`,
        text: 'Tem certeza que deseja confirmar a baixa na transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.payConfirm(item);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.chart-legend-item {
  display: block;
}
.chart-legend-item .icon-category-overview {
  float: left;
}
.chart-legend-item .icon-category-overview {
  float: left;
}
.chart-legend-item span {
  float: left;
  display: block;
  margin: 2px;
}
.btn-edit-category {
  width: 40px !important;
}
.btn-remove-category {
  width: 40px !important;
}
.btn-pay-transaction {
  width: 40px !important;
}
.slide-tab-header{
  width: 50%;
  float: right;
}
@media (max-width: 547px) {
  .slide-tab-header{
    width: 100%;
  }
}
</style>
